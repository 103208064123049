import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { FaFacebook, FaWhatsapp, FaInstagram, FaPhone, FaTiktok } from 'react-icons/fa';
import logo from '../images/logo.png';
import backgroundImage from '../images/background.jpg';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/SocialMedia.css';

const SocialMedia = () => {
  return (
    <Container className="social-media" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <Row className="justify-content-center align-items-center">
        <Col xs="12" className="text-center">
          <img src={logo} alt="Grow Up Logo" className="img-fluid" />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs="auto">
          <a href="https://www.facebook.com/hadeedEngineeringConsultancy/" target="_blank" rel="noopener noreferrer">
            <FaFacebook className="social-icon" />
          </a>
        </Col>
        <Col xs="auto">
          <a href="https://wa.me/972599326232" target="_blank" rel="noopener noreferrer">
            <FaWhatsapp className="social-icon" />
          </a>
        </Col>
        <Col xs="auto">
          <a href="https://www.instagram.com/hadeed_ps/" target="_blank" rel="noopener noreferrer">
            <FaInstagram className="social-icon" />
          </a>
        </Col>
        <Col xs="auto">
          <a href="tel:+972599326232" target="_blank" rel="noopener noreferrer">
            <FaPhone className="social-icon" />
          </a>
        </Col>
        <Col xs="auto">
          <a href="https://www.tiktok.com/@hadeed_h?is_from_webapp=1&sender_device=pc" target="_blank" rel="noopener noreferrer">
            <FaTiktok className="social-icon" />
          </a>
        </Col>
      </Row>
    </Container>
  );
};

export default SocialMedia;
