import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './styles/App.css';
import Footer from './components/Footer';
import SocialMedia from './components/SocialMedia';


function App() {
  return (
    <Router>
      <div className="app">
        <Routes>
          <Route path="/" element={<SocialMedia />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
