import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col className="footer-text">
          <p>© {new Date().getFullYear()} HADEED Engineering Consultancy. All rights reserved. Done By <a href="https://growup-ps.com/social-media" target="_blank" rel="noopener noreferrer" className="growup-link">Grow Up</a></p>
          </Col>
        </Row>

      </Container>
    </footer>
  );
};

export default Footer;
